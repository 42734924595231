<template>
  <VAutocompleteWithValidation
    v-model="innerValue"
    :async-loading="loading"
    label="Nr"
    placeholder="Nr"
    :items="foundProductByArtNr"
    :search-input.sync="search"
    item-value="id"
    v-bind="$attrs"
    no-filter
    custom-item
  >
    <template #item="{ item }">
      <v-list-item-content v-if="item !== null">
        <v-list-item-title>{{ item.artNr }}</v-list-item-title>
        <v-list-item-subtitle v-if="item.title">{{ item.title }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item v-else disabled>
        <v-list-item-title>Search by number</v-list-item-title>
      </v-list-item>
    </template>
  </VAutocompleteWithValidation>
</template>

<script>
import { computed, defineComponent, onBeforeUnmount, ref, watch } from '@vue/composition-api'

import VAutocompleteWithValidation from '@/components/inputs/VAutocompleteWithValidation'

export default defineComponent({
  name: 'SearchSelectByArtNr',
  components: {
    VAutocompleteWithValidation,
  },
  props: {
    items: {
      default: _ => [],
      type: Array,
    },
    // must be included in props
    value: {
      type: null,
      default: null,
    },
  },
  setup(props, { root: { $store }, emit }) {
    const delayTimer = ref(null)
    const loading = ref(false)
    const search = ref(null)
    const innerValue = ref(null)

    const foundProductByArtNr = computed(_ => $store.state.products.foundProductByArtNr)
    const searchProductByArtNr = search => $store.dispatch('products/searchProductByArtNr', search)
    const clearProductSearchByArtNr = _ => $store.dispatch('products/clearProductSearchByArtNr')

    if (props.value) {
      innerValue.value = props.value
    }

    watch(search, val => val && searchForProductByArtNr(val))
    // Handles internal model changes.
    watch(innerValue, val => emit('input', val))
    // Handles external model changes.
    watch(_ => props.value, val => innerValue.value = val)

    const searchForProductByArtNr = search => {
      if (search.length >= 1) {
        clearTimeout(delayTimer.value)
        delayTimer.value = setTimeout(() => {
          loading.value = true
          searchProductByArtNr(search)
            .then(response => {
              // if (response && response.errors) {
              //   this.errors = response.errors
              // } else {
              //   this.errors = null
              // }
              // this.loading = false
            })
            .catch(error => {
              // this.errors = error
              // this.loading = false
            })
            .finally(() => (loading.value = false))
        }, 500)
      }
    }

    onBeforeUnmount(_ => {
      clearProductSearchByArtNr()
    })

    return {
      innerValue,
      loading,
      foundProductByArtNr,
      search,
    }
  },
})
</script>
