<template>
  <VAutocompleteWithValidation
    v-model="innerValue"
    :async-loading="loading"
    label="Title"
    placeholder="Title"
    :items="foundProductByTitle"
    :search-input.sync="search"
    item-value="id"
    v-bind="$attrs"
    no-filter
    custom-item
  >
    <template #item="{ item }">
      <v-list-item-content v-if="item !== null">
        <v-list-item-title>{{ item.artNr }}</v-list-item-title>
        <v-list-item-subtitle v-if="item.title">
          {{ item.title }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item v-else disabled>
        <v-list-item-title> Search by title </v-list-item-title>
      </v-list-item>
    </template>
  </VAutocompleteWithValidation>
</template>

<script>
import { computed, defineComponent, onBeforeUnmount, ref, watch } from '@vue/composition-api'

import VAutocompleteWithValidation from '@/components/inputs/VAutocompleteWithValidation'

export default defineComponent({
  name: 'SearchSelectByTitle',
  components: {
    VAutocompleteWithValidation,
  },
  props: {
    items: {
      default: _ => [],
      type: Array,
    },
    // must be included in props
    value: {
      type: null,
      default: null,
    },
  },
  setup(props, { root: { $store }, emit }) {
    const delayTimer = ref(null)
    const loading = ref(false)
    const search = ref(null)
    const innerValue = ref(null)

    const foundProductByTitle = computed(_ => $store.state.products.foundProductByTitle)
    const searchProductByTitle = search => $store.dispatch('products/searchProductByTitle', search)
    const clearProductSearchByTitle = _ => $store.dispatch('products/clearProductSearchByTitle')

    if (props.value) {
      innerValue.value = props.value
    }

    watch(search, val => val && searchForProductByTitle(val))
    // Handles internal model changes.
    watch(innerValue, val => emit('input', val))
    // Handles external model changes.
    watch(_ => props.value, val => innerValue.value = val)

    const searchForProductByTitle = search => {
      if (search.length >= 3) {
        clearTimeout(delayTimer.value)
        delayTimer.value = setTimeout(() => {
          loading.value = true
          searchProductByTitle(search)
            .then(response => {
              // if (response && response.errors) {
              //   this.errors = response.errors
              // } else {
              //   this.errors = null
              // }
              // this.loading = false
            })
            .catch(error => {
              // this.errors = error
              // this.loading = false
            })
            .finally(() => (loading.value = false))
        }, 500)
      }
    }

    onBeforeUnmount(_ => {
      clearProductSearchByTitle()
    })

    return {
      innerValue,
      loading,
      foundProductByTitle,
      search,
    }
  },
})
</script>
